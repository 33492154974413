.Commands {
  border-radius: 25px;
  border: 2px solid #FFFFBB;
  background-color: #282c34;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
  margin-left: 5px;
  margin-right: 5px;
  font-size: calc(7px + 2vmin);
}

.NoBorder {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  padding: 0;
}

.Text {
  margin:0;
  padding:0;
}

.CommandButton {
  font-size: calc(15px + 2vmin);
  margin: 10px;
  padding: 5px;
}

.TrashButton {
  width: calc(40px + 2vmin);
  height: calc(25px + 2vmin);
}

.TrashImage {
  width: calc(12px + 2vmin);
  height: calc(12px + 2vmin);
  padding-top: 2px;
}
