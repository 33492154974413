.App {
  text-align: center;
  background-image: url('https://assets.pokerhandpost.com/img/suits_ssd_blue.svg');
  background-repeat: repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(13px + 2vmin);
  font-weight: bold;
  color: white;
}

.App-link {
  color: #61dafb;
}

.InfoBox {
  border-radius: 25px;
  border: 2px solid #FFFFBB;
  padding: 10px;
  font-size: calc(10px + 2vmin);
  background-color: #282c34;
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;
}

.Instructions {
  max-width: 90vmin
}

.copyright {
  font-size: calc(8px + 1vmin);
  width: 100%;
  margin-top: 10px;
}

.BoxTitle {
  margin-top: 2vmin;
}

.Logo {
  width: 400px;
  margin-top: 10px;
}
