.TrashButton {
  width: calc(40px + 2vmin);
  height: calc(25px + 2vmin);
  background-color: #282c34;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  color: #FFFFFF;
}

.TrashImage {
  width: calc(12px + 2vmin);
  height: calc(12px + 2vmin);
  padding-top: 2px;
}
