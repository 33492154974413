.AddPlayer {
  border-radius: 25px;
  border: 2px solid #FFFFBB;
  background-color: #282c34;
  padding: 0px;
  margin-top: 20px;
  font-size: calc(7px + 2vmin);
  color: #FFFFFF;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.NoBorder {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  padding: 0;
}

.Text {
  margin:0;
  padding:0;
}

.AddPlayerButton {
  font-size: calc(15px + 2vmin);
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFFFFF;
}
