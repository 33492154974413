.CardChooser {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  border-radius: 25px;
  border: 2px solid #FFFFBB;
  padding: 5px;
  background-color: #282c34;
}

.ChooserCardImage {
  height: 25vmin;
}

.DisabledCard {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) alpha(opacity=50);
  opacity: 0.5;
}

.SuitButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.SuitImage {
  height: 20vmin;
  pointer-events: none;
}
