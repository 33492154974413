.ImageStyleChooser {
  font-size: calc(7px + 2vmin);
  text-align: center;
  font-weight: bold;
}

.ThemeBox {
  border-radius: 25px;
  padding: 10px;
  font-size: calc(10px + 2vmin);
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;
}

.ThemeBox1 {
  color: #FFFFBB;
  background-color: #282c34;
  border: 2px solid #FFFFBB;
}

.ThemeBox2 {
  color: #FFFFFF;
  background-color: #000000;
  border: 2px solid #FFFFFF;
}

.ThemeBox3 {
  color: #333333;
  background-color: #EEEEEE;
  border: 2px solid #333333;
}

.ThemeBox4 {
  color: #FFFFFF;
  background-color: #7d00a3;
  border: 2px solid #FFFFFF;
}

.ThemeBox5 {
  color: #000000;
  background-color: #ffb3d1;
  border: 2px solid #000000;
}

.ThemeBox6 {
  color: #000000;
  background-color: #a0ff99;
  border: 2px solid #000000;
}

.ThemeBox7 {
  color: #000000;
  background-color: #ffe066;
  border: 2px solid #000000;
}

.ThemeBox8 {
  color: #000000;
  background-color: #99ddff;
  border: 2px solid #000000;
}

.ThemeBox9 {
  color: #000000;
  background-color: #ffffcc;
  border: 2px solid #000000;
}

.ThemeBox10 {
  color: #FFFFFF;
  background-color: #0510cc;
  border: 2px solid #FFFFFF;
}

.ThemeBox11 {
  color: #FFFFFF;
  background-color: #ff5033;
  border: 2px solid #FFFFFF;
}

.ThemeBox12 {
  color: #EEEEEE;
  background-color: #004400;
  border: 2px solid #EEEEEE;
}

.NoBorder {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  padding: 0px;
}

.Border {
  background-color: transparent;
  border-color: #FFFFFF;
  margin: 0;
  padding: 0px;
}
