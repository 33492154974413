.PlusButton {
  width: calc(36px);
  height: calc(36px);
  background-color: #282c34;
  border-radius: 8px;
  text-align: center;
  vertical-align: top;
  display: table-cell;
  text-decoration: none;
  display: inline-block;
  color: #FFFFFF;
  padding: 0;
  margin-top: 5px;
}
