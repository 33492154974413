.Details {
  border-radius: 25px;
  border: 2px solid #FFFFBB;
  padding: 10px;
  font-size: calc(7px + 2vmin);
  text-align: center;
}

.BlindsInputField {
  font-size: calc(15px + 2vmin);
  margin-left: 10px;
  margin-bottom: 0px;
  width: calc(300px + 2vmin);
  align: 'right';
}

.InputArea {
  font-size: calc(8px + 2vmin);
  width: calc(350px + 2vmin);
  height: calc(70px + 2vmin);
}

.TrashButton {
  width: calc(40px + 2vmin);
  height: calc(25px + 2vmin);
}

.TrashImage {
  width: calc(12px + 2vmin);
  height: calc(12px + 2vmin);
  padding-top: 2px;
}

.NoBorder {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  padding: 0;
}
